import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import './multilogin.scss'
import studentImage from 'src/assets/images/groupstudy.jpg'
import parentImage from 'src/assets/images/groupstudy.jpg'
import counselorImage from 'src/assets/images/Counseling.jpg'
import tutorImage from 'src/assets/images/on1tutoring.jpg'
import librarianImage from 'src/assets/images/Counseling.jpg'
import journeyImage from 'src/assets/images/1journey.png'
import bam from 'src/assets/images/bussiness.jpg'
import { Spin } from 'antd' // Import Ant Design's Spinner component

const MultiLogin = () => {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(null) // State to control which tile is loading

  const handleRedirect = async (event) => {
    event.preventDefault()
    const attributeValue = event.target.getAttribute('data-value')
    const emailMap = {
      Student: 'student@yopmail.com',
      Parent: 'parent@yopmail.com',
      Counselor: 'counselor@gmail.com',
      Tutor: 'tutor@gmail.com',
      Librarian: 'librarian@gmail.com',
      BAMAdmin: 'bamadmin@gmail.com',
    }
    const password = 'password'

    setLoading(attributeValue) // Set the loading state to the clicked dashboard name

    try {
      const response = await axios.post('https://soft.recipe4foodies.com/api/Account/Login', {
        email: emailMap[attributeValue],
        password: password,
      })

      const { token, userId, roles } = response.data

      localStorage.setItem('token', token)
      localStorage.setItem('userId', userId)
      localStorage.setItem('roles', JSON.stringify(roles))
      localStorage.setItem('AdminProxyScreen', attributeValue)

      // Simulate a small delay before navigating
      setTimeout(() => {
        window.location.href = `/${attributeValue.toLowerCase()}Dashboard`
      }, 1000)
    } catch (error) {
      console.error('Login error:', error)
    } finally {
      setLoading(null) // Reset loading state after the process is complete
    }
  }

  const handleLogout = () => {
    localStorage.setItem('logout', true)
    localStorage.clear()
    navigate('/login')
  }
  const handleRegister = () => {
    navigate('/Register')
  }

  return (
    <div className="multilogin-container">
      <div className="logout-button">
        <button className="btn btn-success" onClick={handleRegister}>
          Tutor and Counselor Registration
        </button>
        &nbsp;
        <button className="btn btn-primary" onClick={handleLogout}>
          Logout
        </button>
      </div>
      <div className="header-image">
        <img src={journeyImage} alt="Journey" style={{ width: '50%' }} />
      </div>
      <div className="tile-container">
        <div
          className="tile"
          onClick={handleRedirect}
          style={{ backgroundImage: `url(${studentImage})`, cursor: 'pointer' }}
          data-value="Student"
        >
          {loading === 'Student' ? (
            <Spin size="large" /> // Show spinner for Student
          ) : (
            <h3 data-value="Student">Student</h3> // Default name when not loading
          )}
        </div>
        <div
          className="tile"
          onClick={handleRedirect}
          style={{ backgroundImage: `url(${parentImage})`, cursor: 'pointer' }}
          data-value="Parent"
        >
          {loading === 'Parent' ? (
            <Spin size="large" /> // Show spinner for Parent
          ) : (
            <h3 data-value="Parent">Parent</h3> // Default name when not loading
          )}
        </div>
        <div
          className="tile"
          onClick={handleRedirect}
          style={{ backgroundImage: `url(${counselorImage})`, cursor: 'pointer' }}
          data-value="Counselor"
        >
          {loading === 'Counselor' ? (
            <Spin size="large" /> // Show spinner for Counselor
          ) : (
            <h3 data-value="Counselor">Counselor</h3> // Default name when not loading
          )}
        </div>
        <div
          className="tile"
          onClick={handleRedirect}
          style={{ backgroundImage: `url(${tutorImage})`, cursor: 'pointer' }}
          data-value="Tutor"
        >
          {loading === 'Tutor' ? (
            <Spin size="large" /> // Show spinner for Tutor
          ) : (
            <h3 data-value="Tutor">Tutor</h3> // Default name when not loading
          )}
        </div>
        <div
          className="tile"
          onClick={handleRedirect}
          style={{ backgroundImage: `url(${librarianImage})`, cursor: 'pointer' }}
          data-value="Librarian"
        >
          {loading === 'Librarian' ? (
            <Spin size="large" /> // Show spinner for Librarian
          ) : (
            <h3 data-value="Librarian">Library</h3> // Default name when not loading
          )}
        </div>
        <div
          className="tile"
          data-value="BAMAdmin"
          onClick={handleRedirect}
          style={{ backgroundImage: `url(${bam})`, cursor: 'pointer' }}
        >
          {loading === 'BAMAdmin' ? (
            <Spin size="large" /> // Show spinner for BAMAdmin
          ) : (
            <h3 data-value="BAMAdmin">BAM</h3> // Default name when not loading
          )}
        </div>
      </div>
    </div>
  )
}

export default MultiLogin

// src/Routes/AuthLazyLoading.js
import { lazy } from 'react'

// Lazy loading the components
export const Login = lazy(() => import('src/Pages/StudentPages/login/Login'))
export const Register = lazy(() => import('src/Common/Register'))
export const StdRegister = lazy(() => import('src/Pages/StudentPages/register/Register'))
export const ForgotPassword = lazy(() =>
  import('src/Pages/StudentPages/ForgotPassword/forgotpassword'),
)
export const ResetPassword = lazy(() =>
  import('src/Pages/StudentPages/resetPassword/ResetPassword'),
)
export const SetPassword = lazy(() => import('src/Pages/StudentPages/register/SetPassword'))
export const EmailConfirmation = lazy(() =>
  import('src/Pages/StudentPages/register/ConfirmationEmail'),
)
export const EmailVerification = lazy(() =>
  import('src/Pages/StudentPages/register/EmailVerification'),
)
export const TutorLogin = lazy(() => import('src/Pages/TutorPages/TutorLogin/TutorLogin'))
export const CounselorLogin = lazy(() =>
  import('src/Pages/CounselorPages/authentication/CounselorLogin/CounselorLogin'),
)

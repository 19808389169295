import React, { Suspense, lazy, useState } from 'react'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import { SoftUIControllerProvider } from 'src/context'
import 'bootstrap/dist/css/bootstrap.min.css'
import MultiLogin from 'src/AdminLayouts/MultiLogin'
import 'src/Common/global.scss'
import * as AuthRoutes from './Routes/AuthLazyLoading'
import PropTypes from 'prop-types'
import { setupInterceptors } from './Common/apiClient'

// Lazy-loaded content for specific users
const AppTutorContent = lazy(() => import('src/Pages/TutorPages/TutorContent/TutorContent'))
const AppStudentContent = lazy(() => import('src/Pages/StudentPages/StudentContent/StudentContent'))
const LibraryContent = lazy(() => import('src/Pages/LibraryPages/LibraryContent/LibraryContent'))
const AppParentContent = lazy(() => import('src/Pages/ParentPages/ParentContent/ParentContent'))
const CounselorContent = lazy(() =>
  import('src/Pages/CounselorPages/CounselorContent/CounselorContent'),
)
const AppBAMContent = lazy(() => import('src/Pages/BAMPages/BAMContent/BAMContent'))
const AdminContent = lazy(() => import('src/AdminLayouts/MultiLogin'))

const AppContent = () => {
  const userRole = localStorage.getItem('role')
  const proxyScreen = localStorage.getItem('AdminProxyScreen')

  if (userRole === 'Tutor' || (userRole === 'Admin' && proxyScreen === 'Tutor')) {
    return <AppTutorContent />
  }
  if (userRole === 'Student' || (userRole === 'Admin' && proxyScreen === 'Student')) {
    return <AppStudentContent />
  }
  if (userRole === 'Parent' || (userRole === 'Admin' && proxyScreen === 'Parent')) {
    return <AppParentContent />
  }
  if (userRole === 'Counselor' || (userRole === 'Admin' && proxyScreen === 'Counselor')) {
    return <CounselorContent />
  }
  if (userRole === 'Librarian' || (userRole === 'Admin' && proxyScreen === 'Librarian')) {
    return <LibraryContent />
  }
  if (userRole === 'BAMAdmin' || (userRole === 'Admin' && proxyScreen === 'BAMAdmin')) {
    return <AppBAMContent />
  }
  if (userRole === 'Admin') {
    return <AdminContent />
  }
  return <Navigate to="/login" />
}

const Loader = ({ loading }) => {
  if (!loading) return null
  return (
    <div className="spinner-container">
      <div className="spinner"></div>
      <div className="spinner-text">Loading...</div>
    </div>
  )
}

Loader.propTypes = {
  loading: PropTypes.bool.isRequired, // Ensures `loading` is a required boolean
}

const App = () => {
  const [loading, setLoading] = useState(true)
  setupInterceptors(setLoading)

  return (
    <BrowserRouter>
      {/* <RouteWithLoader setLoading={setLoading} /> */}
      <Suspense fallback={<Loader loading={loading} />}>
        <SoftUIControllerProvider>
          <Routes>
            <Route path="*" element={<AppContent />} />
            <Route path="/" element={<Navigate to="/login" />} />
            <Route path="/login" element={<AuthRoutes.Login />} />
            <Route path="/tutorLogin" element={<AuthRoutes.TutorLogin />} />
            <Route path="/multiLogin" element={<MultiLogin />} />
            <Route path="/counselorLogin" element={<AuthRoutes.CounselorLogin />} />
            <Route path="/Register" name="Register" element={<AuthRoutes.Register />} />
            <Route path="/studentregister" element={<AuthRoutes.StdRegister />} />
            <Route path="/forgotpassword" element={<AuthRoutes.ForgotPassword />} />
            <Route path="/setPassword" element={<AuthRoutes.SetPassword />} />
            <Route path="/reset-password" element={<AuthRoutes.ResetPassword />} />
            <Route path="/emailConfirmation" element={<AuthRoutes.EmailConfirmation />} />
            <Route path="/emailVerification" element={<AuthRoutes.EmailVerification />} />
          </Routes>
        </SoftUIControllerProvider>
      </Suspense>
    </BrowserRouter>
  )
}

export default App

// // src/App.js
// import React, { Suspense, lazy, useEffect, useState } from 'react'
// import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
// import { SoftUIControllerProvider } from 'src/context'
// import 'bootstrap/dist/css/bootstrap.min.css'
// import MultiLogin from 'src/AdminLayouts/MultiLogin'
// import 'src/Common/global.scss'
// // Import the routes and components from AuthLazyLoading.js
// import * as AuthRoutes from './Routes/AuthLazyLoading'

// // Lazy-loaded content for specific users
// const AppTutorContent = lazy(() => import('src/Pages/TutorPages/TutorContent/TutorContent'))
// const AppStudentContent = lazy(() => import('src/Pages/StudentPages/StudentContent/StudentContent'))
// const LibraryContent = lazy(() => import('src/Pages/LibraryPages/LibraryContent/LibraryContent'))
// const AppParentContent = lazy(() => import('src/Pages/ParentPages/ParentContent/ParentContent'))
// const CounselorContent = lazy(() =>
//   import('src/Pages/CounselorPages/CounselorContent/CounselorContent'),
// )
// const AppBAMContent = lazy(() => import('src/Pages/BAMPages/BAMContent/BAMContent'))
// const AdminContent = lazy(() => import('src/AdminLayouts/MultiLogin'))

// const renderContent = () => {
//   const userRole = localStorage.getItem('role')
//   const proxyScreen = localStorage.getItem('AdminProxyScreen')

//   let AppContent
//   if (userRole === 'Tutor' || (userRole === 'Admin' && proxyScreen === 'Tutor')) {
//     AppContent = <AppTutorContent />
//   } else if (userRole === 'Student' || (userRole === 'Admin' && proxyScreen === 'Student')) {
//     AppContent = <AppStudentContent />
//   } else if (userRole === 'Parent' || (userRole === 'Admin' && proxyScreen === 'Parent')) {
//     AppContent = <AppParentContent />
//   } else if (userRole === 'Counselor' || (userRole === 'Admin' && proxyScreen === 'Counselor')) {
//     AppContent = <CounselorContent />
//   } else if (userRole === 'Librarian' || (userRole === 'Admin' && proxyScreen === 'Librarian')) {
//     AppContent = <LibraryContent />
//   } else if (userRole === 'BAMAdmin' || (userRole === 'Admin' && proxyScreen === 'BAMAdmin')) {
//     AppContent = <AppBAMContent />
//   } else if (userRole === 'Admin') {
//     AppContent = <AdminContent />
//   }

//   return (
//     <Suspense>
//       <BrowserRouter>
//         <SoftUIControllerProvider>
//           <Routes>
//             <Route path="*" element={AppContent} />
//             <Route path="/" element={<Navigate to="/login" />} />
//             <Route path="/login" element={<AuthRoutes.Login />} />
//             <Route path="/tutorLogin" element={<AuthRoutes.TutorLogin />} />
//             <Route path="/multiLogin" element={<MultiLogin />} />
//             <Route path="/counselorLogin" element={<AuthRoutes.CounselorLogin />} />
//             <Route path="/Register" name="Register" element={<AuthRoutes.Register />} />
//             <Route path="/studentregister" element={<AuthRoutes.StdRegister />} />
//             <Route path="/forgotpassword" element={<AuthRoutes.ForgotPassword />} />
//             <Route path="/setPassword" element={<AuthRoutes.SetPassword />} />
//             <Route path="/tutorSetPassword" element={<AuthRoutes.TutorSetPassword />} />
//             <Route path="/counselorSetPassword" element={<AuthRoutes.CounselorSetPassword />} />
//             <Route path="/reset-password" element={<AuthRoutes.ResetPassword />} />
//             <Route path="/tutorResetPassword" element={<AuthRoutes.TutorResetPassword />} />
//             <Route path="/counselorResetPassword" element={<AuthRoutes.CounselorResetPassword />} />
//             <Route path="/emailConfirmation" element={<AuthRoutes.EmailConfirmation />} />
//             <Route path="/emailVerification" element={<AuthRoutes.EmailVerification />} />
//             <Route path="*" element={<AuthRoutes.Login />} />
//           </Routes>
//         </SoftUIControllerProvider>
//       </BrowserRouter>
//     </Suspense>
//   )
// }

// export default function App() {
//   const [loading, setLoading] = useState(true)

//   useEffect(() => {
//     const timer = setTimeout(() => {
//       setLoading(false)
//     }, 5000)
//     return () => clearTimeout(timer)
//   }, [])

//   if (loading) {
//     return (
//       <div className="spinner-container">
//         <div className="spinner"></div>
//         <div className="spinner-text">Loading...</div>
//       </div>
//     )
//   }
//   return <>{renderContent()}</>
// }
